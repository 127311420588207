.Apphead{

    background-color: black;
    min-height: 100vh;
  
}


.btn2 {
    position: absolute;
    top: 460px;
    padding: 15px;
    text-transform: uppercase;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    color: black;
    background-color: white;
    border-width:0;
  }

.btn2:hover,
.btn2:focus,
.btn2:active,
.btn2.active,
.open .dropdown-toggle.btn2 {
  color: white;
  background-color: black;
  
}

.btn2.hover,
.light{
    position:absolute;
    top:265px;
    width:100px;
  }

  .App-headers {
    display: flex;
    
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 4vmin);
    
  }

 .text{
  position:absolute;
  content: fit;
  top: 0px;
  text-align: center;
  font-size: large;
  color:white;
  font-weight: bold;
 }
 .text2{
  content: fit;
  margin:75px;
  font-size: small;
  color:white;
  font-weight: bold;
  margin-left: 400px;
  margin-right:400px;
 }