.App-head{

  background-color: #ffe0e0;
  min-height: 100vh;
}

.App-header {
  display: flex;
  
  align-items: center;
  justify-content: center;
  font-size: calc(1px + 4vmin);
  
}

.svg{
  height: 40px;
  width: 40px;
}
.btn-primary {
  border-color: #fff;
  position: absolute;
  top: 575px;
  padding: 15px;
  text-transform: uppercase;
  font-family: 'Signika', sans-serif;
  font-weight: 700;
  color: #fff;
  background-color: #ffaafb;
  border-width:0;
  border-radius:25px;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #f9a7ff;
}

.image-test{
  position: absolute;
  width:100px;
  top: 650px;
  border: 5px solid rgb(0, 0, 0);
}
.image-test:hover{
  border: 5px solid rgb(255, 255, 255);
}
.img1{
  transform: scaleX(-1);
  width: 125px;
}
.img2{
  width:125px;
}
.img3{
  width:300px;
}

.cake{
  top:250px;
  position: absolute;
  width:300px;
}

@keyframes moveUpAnimation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-500px);
  }
}

.object {
  position: absolute;
  bottom:0px;
  left:50px;
  /* Other styles */
}

.move-up {
  animation: moveUpAnimation 5s infinite;
}

.object2 {
  position: absolute;
  bottom:0px;
  left:1200px;
  /* Other styles */
}